import React, { useState, useEffect } from 'react';
import { List, Avatar, Button, Descriptions, Statistic, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout'
import { useNavigate } from 'react-router-dom';
import { db } from "../firebaseConfig";
import './ChatsList.css';
import moment from 'moment';
import 'moment/locale/gl';

import { collection, doc, getDocs, orderBy, query, where, limit } from "firebase/firestore";

moment.locale('gl');

const ChatsList = () => {
    const [messages, setMessages] = useState([]);
    const [conversations, setConversations] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchConversations = async () => {
            const conversationsRef = collection(db, 'conversations');
            const conversationsSnapshot = await getDocs(conversationsRef);
    
            const fetchedConversationsPromises = conversationsSnapshot.docs.map(async (doc) => {
                const docData = doc.data();
                const messagesRef = collection(doc.ref, 'messages');
                const q = query(messagesRef, orderBy('timestamp', 'desc'), limit(1));
                const messagesSnapshot = await getDocs(q);
    
                let lastMessage = messagesSnapshot.docs[0]?.data() || null;
    
                // Filtro para ocultar mensajes futuros
                if (lastMessage && lastMessage.timestamp) {
                    const lastMessageDate = moment(lastMessage.timestamp.toDate()).startOf('day'); // Obtiene la fecha del último mensaje y la normaliza al inicio del día
                    const currentDate = moment().startOf('day'); // Obtiene la fecha actual y la normaliza al inicio del día

                    // Filtro para ocultar mensajes que no sean del día actual
                    if (lastMessage && lastMessage.timestamp && lastMessage.timestamp.toDate() > new Date()) {
                        lastMessage = null;  // Establece lastMessage a null si el mensaje es del futuro
                    }
                }

                return {
                    id: doc.id,
                    ...docData,
                    lastMessage: lastMessage, // lastMessage será null si el mensaje no es del día actual
                };
            });
            const fetchedConversations = await Promise.all(fetchedConversationsPromises);
            setConversations(fetchedConversations.filter(conversation => conversation.lastMessage !== null)); // Filtra las conversaciones para excluir aquellas cuyo último mensaje es del futuro
        };
        fetchConversations();
                // Establece un intervalo para refrescar las conversaciones cada minuto
                const interval = setInterval(fetchConversations, 60000);

                // Limpia el intervalo cuando el componente se desmonta
                return () => clearInterval(interval);
    }, []);
    

    const handleClick = (id) => {
        navigate(`/chat/${id}`);
    };

    const extraContent = (
        <div className="extraContent">
            <Statistic
                title="Conversas totais"
                value="16"
                style={{
                    marginRight: 32,
                }}
            />
            <Statistic title="Posición no equipo" value="20/345" style={{
                marginRight: 32,
            }} />
            <Statistic title="Visitas ao perfil" value="2.124" />
        </div>
    );


    const PageHeaderComponent = () => (
        <PageHeader
            className="site-page-header-responsive custom-page-header"
            title={
                <div className="custom-title-subtitle">
                    <Avatar
                        className="andrea-avatar"
                        src="https://aqueladas.eu/wp-content/uploads/2024/04/RENT-A-PAL_frame-2.jpg"
                        alt="Andrea's avatar"
                    />
                    <div className="custom-title-container">
                        <div className="custom-title">Ola Andrea, consulta os teus chats recentes</div>
                        {/* Usa la longitud del array 'conversations' para mostrar el número de conversaciones */}
                        <div className="custom-subtitle">Número de conversas novas:<span className="custom-subtitle-3"> {conversations.length}</span></div>
                    </div>
                </div>
            }
        >
        </PageHeader>
    );
    


return (
    <>
        <div className="header-content-wrapper">
            <PageHeaderComponent />
            {extraContent}
        </div>

        <div className="conversation-list">
            <List
                className="conversation-item"
                itemLayout="horizontal"
                dataSource={conversations}
                renderItem={conversation => (
                    <List.Item className="conversation-list__item" onClick={() => handleClick(conversation.id)}>
                        <List.Item.Meta
                            className="conversation-list__meta"
                            avatar={<Avatar src={conversation.imageUrl ? conversation.imageUrl : "https://via.placeholder.com/40"} className="conversation-avatar" />}
                            title={<h2 className="conversation-title">{`${conversation.name}`}</h2>}
                            description={
                                <div className="conversation-last-message-container">
                                    <p className="conversation-last-message">
                                        {conversation.lastMessage?.content ? conversation.lastMessage.content : 'No last message'}
                                    </p>
                                    {conversation.lastMessage?.timestamp && (
                                        <span className="conversation-last-message-time">
                                            {moment(conversation.lastMessage.timestamp.toDate()).fromNow()}
                                        </span>
                                    )}
                                </div>
                            }
                        />
                    </List.Item>
                )}
            />
        </div>


    </>
);


};

export default ChatsList;