import React, { useState, useEffect } from 'react';
import { Badge, Popover, List } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { db } from '../firebaseConfig';

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            const snapshot = await db.collection('notifications').get();
            const fetchedNotifications = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setNotifications(fetchedNotifications);
        };
        fetchNotifications();
    }, []);

    const newNotificationsCount = notifications.filter((notification) => !notification.read).length;

    const content = (
        <List
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta title={item.title} description={item.description} />
                </List.Item>
            )}
            style={{ minWidth: 250 }}
        />
    );

    return (
        <Popover placement="bottomRight" title="Notificacions" content={content} trigger="click">
            <Badge count={newNotificationsCount} style={{ cursor: 'pointer' }}>
                <BellOutlined style={{ fontSize: 24, color: 'white' }} />
            </Badge>
        </Popover>
    );
};

export default Notifications;
