import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { List, Avatar, Typography, Input, Breadcrumb } from 'antd';
import { db } from "../firebaseConfig";
import './ChatConversation.css';

import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";


const ChatConversation = () => {
    const [messages, setMessages] = useState([]);
    const [conversation, setConversation] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchConversation = async () => {
            const conversationRef = doc(collection(db, 'conversations'), id);
            const conversationSnapshot = await getDoc(conversationRef);

            if (conversationSnapshot.exists()) {
                setConversation({
                    id: conversationSnapshot.id,
                    ...conversationSnapshot.data(),
                });
            }
        };

        const fetchMessages = async () => {
            const messagesRef = collection(db, 'conversations', id, 'messages');
            const messagesQuery = query(messagesRef, orderBy('timestamp'));
            const snapshot = await getDocs(messagesQuery);
            const fetchedMessages = snapshot.docs.map(doc => ({
                id: doc.id,
                content: doc.data().content,
                timestamp: doc.data().timestamp,
                sender: doc.data().sender
            }));
            setMessages(fetchedMessages);
        };

        fetchConversation();
        fetchMessages();
    }, [id]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        console.log('Message sent:', e.target[0].value);
        // Add your code here to send the message to Firebase
    }

    if (!conversation) {
        return <Typography.Text>Loading...</Typography.Text>;
    }

    return (
        <div className="chat">
            <div className="chat-header">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/chats">Chats</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{conversation.name}</Breadcrumb.Item>
                </Breadcrumb>
                <Link to={`/profile/${conversation.name}?conversationId=${id}`} className="chat-profile-link">
                    <Avatar src={conversation.imageUrl} />
                    <Typography.Text>{conversation.name}</Typography.Text>
                </Link>

                <Typography.Title level={3} className="chat-title">{`Desfrutade do match :)`}</Typography.Title>

            </div>
            <div className="chat-messages">
                <List
                itemLayout="horizontal"
                dataSource={messages}
                renderItem={message => (
                    <List.Item>
                        <List.Item.Meta
                            description={
                                <div className={`message-bubble ${message.sender === 'Andrea' ? 'message-bubble-right' : 'message-bubble-left'}`}>
                                    <p className="message-content">{message.content}</p>
                                    <small>{new Date(message.timestamp.seconds * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</small>
                                </div>
                            }
                        />
                    </List.Item>
                )}
                />
            </div> 
        
        <form className="chat-input" onSubmit={handleSendMessage}>
            <Input type="text" placeholder="Mensaxes desactivados ata a seguinte cita" className="enviar-mensaxe" />
            <button type="submit" className="send-button" disabled>Enviar</button>
            </form></div>
    );
};

export default ChatConversation;
