import React, { useState, useEffect } from "react";
import "./Post.css";

const Post = ({ post: { title, body, imgUrl, author, timestamp }, index }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTimePast, setIsTimePast] = useState(false);

    const truncatedBody = body.length > 250 ? body.substring(0, 250) + "..." : body;

    useEffect(() => {
        const timer = setInterval(() => {
            setIsTimePast(new Date() >= new Date(timestamp));
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [timestamp]);

    if (!isTimePast) {
        return null;
    }

    return (
        <div className="post-container">
            <h1 className="heading">{title}</h1>
            <div className="info">
                <h4>Escrito por: {author}</h4>
                <p>Publicado o día {new Date(timestamp).toLocaleDateString('gl', { year: 'numeric', month: 'short', day: 'numeric' })} ás {new Date(timestamp).toLocaleTimeString('gl', { hour: '2-digit', minute: '2-digit' })}</p>
            </div>
            <p>
                {isExpanded ? body : truncatedBody}
            </p>
            
            <button className="show-more-button" onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? "Pechar" : "Ler máis"}
            </button>
        </div>
    );
};

export default Post;