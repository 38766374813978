import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import ChatsList from './components/ChatsList';
import ChatConversation from './components/ChatConversation';
import Profile from './components/Profile';
import Info from './components/Info';
import Notifications from './components/Notifications';
import Dashboard from './components/Dashboard';
import Blog from './components/Posts';
import UserProfile from './components/UserProfile';
import './App.css';
import { db } from './firebaseConfig';
import { Link } from 'react-router-dom';
import logo from './assets/logo-rentapal.png'
import { Layout, Menu, Button, ConfigProvider, theme } from 'antd';
import { InfoCircleOutlined, DashboardOutlined, UserOutlined, MessageOutlined, BookOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
const { Header, Content, Footer, Sider } = Layout;

function MainContent() {

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        // Step 1: Get all conversations
        db.collection('conversations').get().then(conversationsSnapshot => {
            // Step 2: For each conversation, set up a listener for the 'messages' subcollection
            conversationsSnapshot.docs.forEach(conversationDoc => {
                db.collection('conversations').doc(conversationDoc.id).collection('messages')
                    .onSnapshot(messagesSnapshot => {
                        const newNotifications = [];
    
                        messagesSnapshot.docChanges().forEach(change => {
                            if (change.type === "added") {
                                console.log('New message detected:', change.doc.data()); // Add this line
                                newNotifications.push({
                                    id: change.doc.id,
                                    ...change.doc.data(),
                                });
                            }
                        });
    
                        if (newNotifications.length > 0) {
                            console.log('Updating notifications state:', newNotifications); // Add this line
                            setNotifications(prev => [...prev, ...newNotifications]);
                        }
                    });
            });
        });
    }, []);
    const location = useLocation();
    const isLandingPage = location.pathname === "/";
    const showSideMenu = location.pathname !== "/";
    const showHeader = location.pathname !== "/";
    const showFooter = !isLandingPage;
    const showScroll = location.pathname !== "/";
    const menuItems = [
        { key: "1", icon: <DashboardOutlined />, label: "Panel de control", path: "/dashboard" },
        { key: "2", icon: <MessageOutlined />, label: "Conversas", path: "/chats" },
        { key: "3", icon: <BookOutlined />, label: "Blog", path: "/posts" },
        { key: "4", icon: <UserOutlined />, label: "Perfil", path: `/profile/Andrea` }, // Note the change here
        { key: "5", icon: <InfoCircleOutlined />, label: "Info", path: "/info" },
    ];


    return (
        <Layout className="layout-container">
            {!isLandingPage && showHeader && showFooter && (

            <Header
                className="app-header"
                style={{
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
                >
                    <Link to={`/`}>
                        <img src={logo} alt="Rent-a-pal Logo" className="app-logo" />
                    </Link>
                    <h1 className="title">Benvida, Andrea!</h1>
                    <div className="notification-container">
                        <Notifications className="notification-icon" notifications={notifications} />
                    </div>
                </Header>)}

            <Layout>
                {showSideMenu && (
                    <Sider
                        className="ant-layout-sider"
                        breakpoint="lg"
                        collapsedWidth="0"
                        onBreakpoint={(broken) => {
                            console.log(broken);
                        }}
                        onCollapse={(collapsed, type) => {
                            console.log(collapsed, type);
                        }}
                    >
                        <div className="logo" />
                   
                        <Menu theme="light" mode="inline" defaultSelectedKeys={['1']}>
                            {menuItems.map(({ key, icon, label, path }) => (
                                <Menu.Item key={key} icon={icon}>
                                    <NavLink exact to={path} className="menu-link"
                                        activeClassName="menu-link-selected">
                                        {label}
                                    </NavLink>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Sider>
                )}
                <Layout>
                    <Content
                        style={{

                            margin: '24px 16px 0',
                        }}
                        style={{
                            margin: isLandingPage ? '0' : '24px 16px 0',
                        }}
                    >
                    
                        <div
                            className={`scrollable-content ${!isLandingPage ? 'non-landing' : ''}`}
                            style={{
                                margin: 0,
                                minHeight: 360,
                            }}
                        >
                            <Routes>
                                <Route path="/" element={<LandingPage />} index />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/chats" element={<ChatsList />} />
                                <Route path="/chat/:id" element={<ChatConversation />} />
                                <Route path="/posts" element={<Blog />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/info" element={<Info />} />
                                <Route path="/profile/:name" element={<UserProfile />} />

                            </Routes>

                        </div>
                    </Content>
                    {showFooter && (
                        <Footer className="app-footer">
                            Rent-a-pal 2024 Creado por <a href="https://aqueladas.eu">Aqueladas</a>
                        </Footer>)}
                </Layout>
            </Layout>
        </Layout>
    );
}

function App() {
    return (
        <Router>
             <Helmet>
                <title>Rent-a-pal</title>
                <meta name="description" content="Web de Rent-a-pal, serie da Televisión de Galicia producida por Aqueladas" />
            </Helmet>
                <MainContent />
                
        </Router>
    );
}


export default App;


