import React from "react";
import Post from "./Post";

const Posts = () => {
	const blogPosts = [
		{
			title: "O principio",
			author: "Andrea",
			imgUrl:"https://media.geeksforgeeks.org/img-practice/banner/diving-into-excel-thumbnail.png",
			timestamp: new Date('2024-04-08T10:35:00'), // The post will be made public on December 31, 2022
			body: `O rumbo das cousas adoita ser curioso. E mesmo caprichoso, diría. Os principios sempre se me deron
			mal. Pero son inevitables, como tantas outras cousas na vida. Así que se decidín iniciar este
			experimento debería empezar por algures.
			O meu nome sería un bo punto de partida. Mais non pretendo utilizar o meu real. De aquí en diante a
			escritora referirase a si mesma como Andrea. Os nomes dos suxeitos referidos tampouco serán reais,
			para preservar o seu anonimato e dereito á intimidade.
			Despois deste disclaimer, porque a honestidade aínda que sobrevalorada está aí... Se tivese que
			explicarlle a alguén como isto comezou a intuición lévame algúns meses atrás. Concretamente o 13 de
			agosto de 2023. Pasaran exactamente, por casualidade, dúas semanas desde que se me acabara o
			paro e andaba lanzando currículos como tola a calquera cousa. Por iso de ter para comer.
			Nesas andaban cando me falaron de Rent-a-pal. Ofrecían un emprego bastante singular. O nome xa o
			di. Ao principio pareceume ata patético. Quen se ía anotar a algo así? Pero os 80 euros a hora
			axudáronme a cambiar de idea.
			O certo é que me inspiraba bastante curiosidade. Que clase de persoas contratarían os servizos dun
			amigo de alugueiro? Cales serían as súas razóns? Como alguén pode pagar por amizade? Ou pola súa
			ilusión, máis ben. Sentín claramente que aí había unha historia. E decidín que era hora de levantarse do
			sofá e ir buscala.
			Así que mandei unha solicitude. E resulta que me chamaron. Despois de pasar dúas unha proba e dúas
			entrevistas de recursos humanos (que dan soas para unha entrega) remataron aceptando que era o
			suficientemente maleable para o posto. Así que me mandaron a convocatoria para un curso de
			formación.
			O proceso foi bastante curioso en si mesmo. Explicáronnos as normas básicas, recollidas no manual da
			empresa. Faláronnos de boas prácticas e déronnos consellos para manexar certas situacións que
			poden darse no día a día do traballo. Fixemos algunhas simulacións de situacións por parellas. Para
			darnos confianza, pero tamén para avaliarnos. A min tocoume o teatriño cunha muller que me propoñía
			algo que a min non me apetecía.
			Explicáronnos tamén como tiñamos que facer para fichar. Unha foto antes e outra despois, para que
			quede claro o tempo e a situación. A formadora impartiuno todo cun sorriso falso de MR Wonderful que
			me resultaba bastante irritante. Pero a contención é unha gran virtude, seica.
			Así as cousas, pasei á fase de prácticas. Porque como en todo bo contrato precario existe un período
			de prácticas na que a empresa pode decidir que cometeu un erro no proceso de selección e non das a
			talla (ou non es o suficientemente explotable). Danche bastante a brasa con que hai inspectores de
			calidade ocultos entre os clientes, que serán parte da túa avaliación, así que coidado co que fas.
			As condicións son as típicas para este tipo de contratos de falso autónomo. Eles facilítanche certas
			cousas, pero ti só cobras en función das horas que fas. É algo tipo UBER. Se che vai guai e te sabes
			desenvolver, consegues clientes habituais e podes acabar facendo bastante pasta por un traballo que
			aparentemente non te mata. Se o teu non é este negocio, terás que saber retirarte ti mesmo no
			momento xusto.
			Así fun á miña primeira cita. Chegoume unha mensaxe dunha persoa á que chamarei Lotte. Resulta que
			era unha turista alemá que estaba na cidade e quería ter a sensación de chegar realmente a coñecela.
			En lugar de acudir a algún servizo de guías turísticos, pareceulle máis interesante utilizar Rent-a-pal
			para poder ter unha experiencia con amigos locais. Comentoume que xa probara máis veces e fora
			bastante interesante. Foi unha primeira experiencia pouco representativa. Ao ser de fóra pasei o día
			facéndolle o meu tour pola cidade, ou acompañándoa aos sitios que ela quería ver. Creo que a
			decepcionou un pouco o meu escaso coñecemento sobre certos detalles da cidade... Pero non estivo
			mal. Crin que a sensación de estar sendo utilizado sería bastante máis incómoda, pero a verdade...
			sentinme ben. Sobre todo cando chegou o ingreso simplemente por dar un par de paseos pola cidade.
			Pensei que podería facer un estudo, contar desde dentro o que implican este tipo de traballos. Pero é
			complicado, claro. A perspectiva da observación-participante sempre tingue, ata certo punto, os
			
			resultados. O meu compromiso é intentar ser o máis obxectiva posible, dentro das limitacións evidentes.
			En todo caso, penso que pode ser interesante entender que está detrás deste tipo de servizos. A clase
			de sociedade que chega a un punto de monetización da realidade como para pensar que a amizade se
			pode comprar.
			Parece algo novo, non? Moi propio do noso tempo. Pero supoño que se ollamos ao pasado, o desexo
			de poder e ambición sempre condicionaron a amizade por interese. Desde as tramas da idade media
			até hoxe. Talvez esta fórmula até sexa máis honesta. Polo menos, as dúas partes son en principio
			conscientes de que se trata dun servizo. De que o vínculo principal é unha transacción comercial. Aínda
			que polo que nos explicaron, parte do noso traballo é buscar unha naturalidade e normalidade absoluta.
			Pagan por esquecerse de que pagan. E como bos clientes, sempre teñen a razón.`,
		},
		{
			title: "O sexo está prohibido",
			timestamp: new Date('2024-04-09T22:35:00'), // The post will be made public on December 31, 2022
			body: `Despois dalgún tempo probando como traballadora de Rent-a-pal, vou compartir unha das experiencias
			máis chocantes que tiven ata a data. Foi a primeira vez que me sentín realmente incómoda co traballo.
			O feito en si aconteceu durante unha cita cun cliente que chamarei Carlos, para preservar como xa
			dixen a súa intimidade. Carlos ten 34 anos. É profesor universitario. É unha desas persoas que sempre
			falan de si mesmas e semellan incapaces de escoitar nada que non teña que ver con elas. Non por
			nada, simplemente non lles interesa.
			Non era a primeira vez que quedabamos. De feito, levaba traza de converterse nun cliente habitual.
			Normalmente vímonos en lugares públicos. Fomos cear, á presentación dun libro... O caso é que
			Carlos, desta volta, propuxo convidarme a cear na súa casa. Xa comentou na primeira cita o bo
			cociñeiro que era e estas cousas, pero chegara o momento de demostralo. Non atopei ningún
			argumento en contra, así que alá fun.
			Ao principio, a cousa non resultou moi diferente doutros traballos. Sermoneoume con como os alumnos
			que ten son idiotas (todos sabemos que iso nunca ten nada que ver co profesor, claro), fixo carne para
			cear cando eu dixen que son vexetariana... Todo dentro do esperable, a verdade.
			A complicación chegou realmente cando rematamos de cear. Insistiu en tomar uns gin tonics, que lle
			saen moi ricos tamén e estas cousas. E polo menos non levan bisté. A verdade non estaban mal, pero
			creo que non chego ao seu nivel de orgasmo con estas cousas.
			O caso, que nese momento comezou a poñerse un pouco pesado. Máis do normal, refírome. Non en
			modo pedante, senón no mal sentido. Carlos comezou a insinuarse de maneira non demasiado sutil. Eu
			indiqueille, con toda a educación que fun capaz de reunir, que unha das normas básicas de Rent-a-pal
			impide as relacións sexuais entre cliente e acompañante. Algo que el xa debería saber. Non é unha app
			de citas, é un servizo de amizade.
			No curso advertíronnos xa claramente que o protocolo nestes casos é recordarlle ao cliente a norma da
			casa de maneira amable e intentar cambiar de tema. Normalmente o sentimento de vergonza polo
			rexeitamento axuda a que acepten este salto como algo normal. Pero tampouco foi o caso. Porque
			Carlos é unha desas persoas que non aceptan tan facilmente un non por resposta.
			Carlos decidiu que eu o desexaba. Sen vir a conto, porque estou segura de que eu nunca alimentei esa
			impresión. Se teño que esforzarme por non bocexar cando comeza a falarme das súas cousas! Debo
			ser mellor actriz do que penso, porque el estaba convencido de que a química entre nós era palpable. E
			entón chegou a contrapartida. O morbo. Non che dá cousa facelo cando está prohibido? Quería berrar
			que o que me daba cousa nese momento era que me tocase.
			Insistinlle de novo en que non era apropiado. E aí subiu a aposta. Porque pode. Porque os cartos todo o
			poden. Ofreceume un extra, fóra da aplicación, a cambio de ter relacións sexuais. Concretamente 100
			euros. Iso é o que vale para un home coma Carlos o corpo dunha muller. E cando me ofendín, por
			suposto, Carlos tiña aínda máis que dicir. E por unha vez se cadra tiña razón.
			Para el non existe gran diferenza entre o que actualmente é o meu traballo e as relacións sexuais a
			cambio de diñeiro, iso que chamamos prostitución. Unha é a entrega do corpo, na ilusión do desexo,
			para compracer un cliente. No meu caso faise coa entrega da alma, simulando ser unha persoa que o
			coñece e a quen lle agrada pasar o seu tempo con el. Pero por algún motivo, esa liña brutal de entrega
			absoluta do corpo semella moito máis abismal para min.
			E non quero que se malinterprete. A prostitución vai con frecuencia acompañada dunha serie de
			condicionantes que non existen no meu caso, desde a falta de liberdade de decisión, a presión
			socioeconómica e todo iso. Se cadra por iso.
			Confeso que o primeiro que me veu á mente nese momento foi: como pode este tío pensar que estou
			tan desesperada como para deitarme con el? Pero esta impresión podo entendela desde a súa banda:
			Que clase de persoa se fai pasar por amiga de alguén?
			Ademais diso, esta anécdota fíxome pensar noutra cousa. Creo que moitos clientes non buscan nin
			sequera esa ilusión de amizade que eu cría. Só están tan desesperadamente sós e son tan
			
			insoportables que lles abonda con compañía. E pensan que poden conseguir saciar as súas
			necesidades a través destes encontros. Entón que é a amizade? Estar aí para alguén? Acompañar?
			Por se quedaba algunha dúbida, erguinme e marchei. Logo informei o meu supervisor, a mesma persoa
			que me envía as citas. El restoulle importancia, volveume co conto de que era nova no choio e talvez o
			alimentara dalgunha maneira... Como non. Eu insistinlle en que non, e que agardaba que lle abrisen
			algún tipo de sanción ou algo. Pero polo que me contou, o mellor era deixalo estar. Iso si, tranquilizoume
			asegurándome que non me volvería citar con el. Que gran alivio...
			[ACTUALIZACIÓN 13/09/2023]
			Non me colle a sorpresa na cabeza. Resulta que Carlos me puxo unha queixa! A min. É que non mo
			creo. O supervisor di que vai haber un expediente para aclarar o acontecido. E como aínda estou en
			período de proba, resulta que vou ter que andar con ollo, porque van estar avaliando con detemento a
			“calidade das miñas interaccións”.`,
			author: "Andrea",
			imgUrl:
				"https://media.geeksforgeeks.org/img-practice/banner/diving-into-excel-thumbnail.png",
		},
		{
			title: "Quen paga manda",
			timestamp: new Date('2024-04-16T22:35:00'), // The post will be made public on December 31, 2022
			body: `Hoxe vou falar de Adela. Adela é unha muller de 60 anos que se sente terriblemente soa. Ou iso imaxino
			eu, que teño tendencia a facerme unha idea da vida das persoas. Ata aí todo ben. Podo imaxinar que
			nun lugar tan envellecido isto é un problema até certo punto frecuente. De feito, a miña predisposición
			inicial é sentir certa compaixón por Elvira. Pobre muller soa, sen ninguén que mire por ela...
			O caso é que tamén podo entender por que ninguén quere tela preto. Duro? Si. Certo? Tamén. Quero
			dicir, de entrada cóntanche a historia e tal, e pensas en que lle pasaría. Cando a coñeces xa te vas
			dando conta de que é unha persoa un pouco singular. Desta xente “difícil de levar”. Pero teño que dicir
			que iso, quitando ocasións moi contadas, non é algo que me moleste excesivamente. É cortante e ten
			moi claro o que quere, son cousas que podo chegar a envexar.
			Despois dun tempo véndonos, na última cita descubrín un pouco máis do seu pasado que me fixo darlle
			voltas á cabeza. Resulta que foi actriz de teatro. Era a súa paixón aínda que a familia non a apoiase, e
			marchou a Barcelona a perseguir o seu soño. Ata que quedou embarazada e se lle fastidiou a aventura
			(parece un melodrama isto, a verdade, pero foi o que me contou). Volveu a Galicia, casou con outro
			(que cousas) e cando os fillos lle medraron un pouco, pois ala, ao teatro outra vez. E foille ben, polo
			menos no profesional.
			Agora non se leva coa familia. Ela bótalle a culpa ao teatro. Temos unha tendencia a non recoñecer
			nunca cando os erros son dun mesmo. O certo é que imaxino que no seu contexto debeu ser
			complicado conciliar o laboral co profesional, pero dáme a sensación de que ten un carácter bastante
			complicado. Xa a min me está sempre intentando levar polo rego, non quero pensar aos seus fillos. Por
			iso digo que entendo que tomasen distancia. Pero ao tempo, se son 100% sincera, non podo evitar que
			Adela me dea certa mágoa. Busca compaña, ten un baleiro enorme pola familia que lle falta. Pero
			incluso cando ten alguén disposto a confortala, a achegarse a esa persoa de verdade, afástaa. Foi así
			comigo e teño a sensación de que non son a única. Cando comezabamos a conectar meteu o corte,
			autoritaria como é ela, e deixoume claro cal é a miña posición. Supoño que tamén ten que ser duro para
			o cliente dalgún xeito, imaxinar que esa persoa que está aí pode estarlle importando unha merda o que
			lle contas, o que está é contando os minutos para saír do choio. O que lle interesan son os billetes. E
			sendo sinceros, é certo. Por moito que poida compadecerme dela, non a visitaría se non fose porque
			me paga. Hai algo nela que me cae ben pero... Tampouco vou ir eu aquí de boa samaritana. É curioso
			este traballo, a verdade. Eu tamén interpreto con ela un papel.`,
			author: "Andrea",
			imgUrl:
				"https://media.geeksforgeeks.org/img-practice/banner/diving-into-excel-thumbnail.png",
		},
		{
			title: "Ás veces pasa",
			timestamp: new Date('2024-04-23T22:35:00'), // The post will be made public on December 31, 2022
			body: `A ver, hoxe estou raiada de verdade. Pasoume algo que de verdade me resultou chocante. Resulta que
			hai unhas semanas me contactou unha rapaza de 18 anos que se chama Mar. Ou iso dicía o perfil, pero
			realmente controlábano seus pais. Xa isto é bastante turbio de por si, non nos vaiamos enganar, pero a
			verdade é que me entrou a curiosidade por entender que hai detrás. Cheiro unha historia interesante,
			así que alá fun.
			Os pais de Mar están preocupados porque a súa filla é unha solitaria. Por iso decidiron pagarlle a
			alguén para que se achegue a ela e lle demostre que pode relacionarse cos demais sen problema.
			Moito mellor iso que falar con ela (quero pensar que o intentaron) ou se cadra aceptar que ten un
			carácter introvertido, ou que hai algún outro tipo de problema.
			A verdade, pensei que a pobre rapaza igual tiña algún tipo de desorde mental e precisaba axuda de
			verdade. Pero despois de coñecela non o creo. Ela está ben, simplemente é así. Talvez non toda as
			persoas teñen que ser o cumio da extroversión e ter 50.000 amigos. Non sei... Pero non nos
			adiantemos.
			Os pais insistíronme en que Mar non podía saber nada do noso acordo. Eu debía parecer
			espontáneamente interesada nela. Suxeríronme un encontro casual nunha libraría que ela adoita
			frecuentar, porque resulta que a rapaza é unha rata de biblioteca. Pero cando cheguei alí, o plan foi ao
			carallo. Mar soubo inmediatamente a que viña e deixoume claro que non quería formar parte da farsa.
			Normalmente estaría do seu lado, porque a verdade é que a historia é bastante rocambolesca. Pero a
			verdade é que me vén un pouco mal que a rapaza non pasar tempo comigo e os pais non me paguen o
			prometido. Desde a queixa de Carlos non me están pasando moitos clientes, supoño que como unha
			especie de castigo, aínda que non podo demostrar nada diso. E hai algo chamado alquiler que me
			reclama todos os meses así que... Para que negalo, preciso os cartos.
			Así que venme mal agora mesmo que a rapaciña sexa espelida de máis. A verdade é que parece unha
			crack. Un pouco idealista de máis, pero iso cúrao a idade. Fáltalle tamén espabilar un pouco con certas
			cousas: Non quere mentirlle aos seus pais, xa me dirás ti. Que adolescente de 18 anos non lle minte
			aos seus pais? O caso, que conseguín convencela para que aceptase o servizo. Fixemos unha especie
			de trato, ela queda comigo e así eu podo cobrar.
			O problema é que ao final todo foi ben. Demasiado ben. Ata o punto de que rematamos abríndonos,
			falando de movidas persoais... E non só ela, tamén eu. E aquí é cando entrei verdadeiramente en
			pánico. Estame facendo perder a perspectiva ren-a-pal? Porque Mar, aínda que dunha maneira moi
			particular, é cliente. E por primeira vez sentín que estaba cómoda, que estaba pasando o tempo con
			alguén interesante, alguén que me comprendía. Non sei, ten ese punto inocente que che fai cuestionar
			as cousas. Preguntoume por que non escribía. E ten razón, supoño. Nunca pensei que puidese ter
			dúbidas, perder o norte ao punto de que alguén me caese ben. Pero non ben como Adela, que é
			soportable. Senón ben como para... Xa sabesdes, para ser un amigo. De verdade. En potencia, vaia.
			Que tampouco vos vaiades pensar que regalo a miña amizade así tan fácil.
			Síntome confusa. Entón que fago? Rexeito os cartos da familia? É unha opción, claro. Pero... no fondo
			fixen o traballo, a ninguén lle vai facer dano. A eles sóbranlles se andan con estas caralladas, non? Que
			dicides, que fago?`,
			author: "Andrea",
			imgUrl:
				"https://media.geeksforgeeks.org/img-practice/banner/diving-into-excel-thumbnail.png",
		},
	];

	return (
		<div className="posts-container">
			{blogPosts.map((post, index) => (
				<Post key={index} index={index} post={post} />
			))}
		</div>
	);
};

export default Posts;
