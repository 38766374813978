import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC6J_GtBWTdrk66hJmvE0dqCZG1amKwIQI",
    authDomain: "rent-a-pal.firebaseapp.com",
    databaseURL: "https://rent-a-pal-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rent-a-pal",
    storageBucket: "rent-a-pal.appspot.com",
    messagingSenderId: "666065735449",
    appId: "1:666065735449:web:0e04bcbfa7630e17fbbf88",
    measurementId: "G-2TLWLB2V9G"
};
const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();

export { app, db };
