// profiles.js
const profiles = [
    {
        id: 'Andrea',
        name: 'Andrea',
        age: '28',
        avatarUrl: 'https://aqueladas.eu/wp-content/uploads/2024/04/RENT-A-PAL_frame-2.jpg',
        bio: 'Lectora empedernida. Viaxeira ocasional. Con ganas de compartir momentos e experiencias 😊🌼',
        preferences: ['Literatura', 'Viaxes', 'Conversa', 'Música', 'Películas'],
    },
    {
        id: 'Adela',
        name: 'Adela',
        age: '82',
        avatarUrl: 'https://aqueladas.eu/wp-content/uploads/2024/04/RENT-A-PAL_CAP01.00_06_02_13.Still008.png',
        bio: 'Bos días. Os meus pasatempos son o xadrez e a costura. Busco compaña ordenada e limpa',
        preferences: ['Cociña', 'Xadrez', 'Costura', 'Televisión'],
    },
    {
        id: 'Mar',
        name: 'Mar',
        age: '18',
        avatarUrl: 'https://aqueladas.eu/wp-content/uploads/2024/04/RENT-A-PAL_CAP01.00_03_08_10.Still012.png',
        bio: 'A partir de agora non viaxarei máis que en soños ✨✈️🌍',
        preferences: ['VIaxar', 'Baile', 'Fotografía', 'Películas'],
    },
        {
        id: 'Carlos',
        name: 'Carlos',
        age: '36',
        avatarUrl: 'https://aqueladas.eu/wp-content/uploads/2024/04/RENT-A-PAL_CAP01.00_02_20_03.Still001.png',
            bio: 'Se che gusta a conversa intelixente e o bo viño son o teu pal perfecto 😆 Falamos? 🍷',
        preferences: ['Viño', 'Conversas', 'Literatura', 'Fitness'],

    },
];

export default profiles;
