import React from 'react';
import profiles from './profiles';
import { Avatar, Typography, Space, Tag, Breadcrumb } from 'antd';
import { useParams, useLocation, Link } from 'react-router-dom';
import './UserProfile.css';
const { Title, Paragraph } = Typography;

const UserProfile = () => {
    const { name } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const conversationId = queryParams.get('conversationId');
    const profile = profiles.find(p => p.name === name);
    if (!profile) {
        return <div>Profile not found</div>;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {
                name !== "Andrea" && (
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/chats">Chats</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/chat/${conversationId}`}>{name}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Perfil de {name}</Breadcrumb.Item>
                    </Breadcrumb>
                )
            }
            <Avatar size={256} src={profile.avatarUrl} className="avatarProfile"/>
            <Space direction="vertical" size="middle" style={{ textAlign: 'center' }}>
                <Title level={2}>{profile.name}, {profile.age}</Title>
                <Paragraph>{profile.bio}</Paragraph>
                <div>
                    {profile.preferences.map((preference, index) => (
                        <Tag key={index} color="#FFCE00" style={{ color: 'black' }}>
                            {preference}
                        </Tag>
                    ))}
                </div>
            </Space>
        </div>
    );
};

export default UserProfile;
