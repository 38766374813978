import React from 'react';
import { Typography, Divider } from 'antd';
import './Info.css';


const { Title, Paragraph } = Typography;

const Info = () => {
    return (
        <div className="info-container">
            <Title level={2}>Sobre Rent-a-pal</Title>
            <Divider />
            <Paragraph>
                Rent-a-pal é unha aplicación revolucionaria que conecta xente que anda na procura de amizades para compartir experiencias.
                A nosa misión é facer este proceso o máis sinxelo posible, permitindo crear conexións duradeiras entre individuos con gustos semellantes. 
            </Paragraph>
            <Paragraph>
                Con Rent-a-pal, podes buscar perfís de xente na túa área, ver os seus intereses, e comezar conversas cuns poucos clicks.
                O noso algoritmo avanzado de emparellamento asegura que só te conectarás con xente que comparta os teus hobbies e paixóns, así sempre terás 
                algo do que falar!
            </Paragraph>
            <Paragraph>
                Xa estés na procura dalguén para facer exercicio, ou para viaxar, ou só para compartir unha cunca de café, Rent-a-pal está aquí para axudarte a atopar o teu pal perfecto. 
            </Paragraph>
            <Title level={3}>Por que Rent-a-pal?</Title>
            <Divider />
            <Paragraph>
                No mundo hiperacelerado de hoxe, pode ser un reto facer novos amigos e manter
                relacións significativas. Rent-a-pal creouse para axudar a cubrir esta brecha e proporcionar
                un xeito sinxelo, divertido e seguro de coñecer xente nova e establecer conexións duradeiras.
            </Paragraph>
            <Paragraph>
                O noso equipo dedícase a ofrecer a mellor experiencia posible aos nosos usuarios e
                traballamos constantemente para mellorar a nosa plataforma e engadir novas funcións para que sexa igual
                máis agradable de usar. Entón, por que esperar? Únete a Rent-a-pal hoxe e comeza a descubrir novas
                amizades e aventuras!
            </Paragraph>
        </div>
    );
};

export default Info;
