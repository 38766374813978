import React from 'react';
import { Avatar, Typography, Space, Tag } from 'antd';

const { Title, Paragraph } = Typography;

const Profile = () => {
    const name = 'Andrea Doe';
    const description =
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero.';
    const preferences = ['Ler', 'Viaxar', 'Fotografía', 'Museos'];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
                size={128}
                src="https://aqueladas.eu/wp-content/uploads/2023/08/andrea.png"
            />
            <Space direction="vertical" size="middle" style={{ textAlign: 'center' }}>
                <Title level={2}>{name}</Title>
                <Paragraph>{description}</Paragraph>
                <div>
                    {preferences.map((preference, index) => (
                        <Tag key={index} color="#FFCE00" style={{color: 'black'}}>
                            {preference}
                        </Tag>
                    ))}
                </div>
            </Space>
        </div>
    );
};

export default Profile;
