import React from 'react';
import './LandingPage.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logo-rentapal.png'
import hero from '../assets/landing-page-1.png'
import section from '../assets/girl-with-laptop.svg'
import fa1 from '../assets/fa6-1.png'
import fa2 from '../assets/fa6-2.png'
import fa3 from '../assets/fa6-3.png'
import avatar1 from '../assets/profile-1.png'
import avatar2 from '../assets/profile-2.png'
import avatar3 from '../assets/profile-3.png'
import { CheckOutlined } from '@ant-design/icons';
import { Card, Avatar, Divider, Space, Tag } from 'antd';
// your code here

const LandingPage = () => {
    return (
        <div className="landing-page">
            <header className="header">
                <img className="logo" src={logo} alt="Logo Rent-a-pal" />
                <Link to="/Dashboard">
                    <button className="header-button">Entra</button>
                </Link>
            </header>

            <section className="hero">
                <div className="hero-text">
                    <h2 className="subtitle">Atopa o teu perfect-pal hoxe!</h2>
                    <p className="description">
                        Vas a unha festa? A unha voda? Un evento familiar? Coñece ao pal ideal para ti
                    </p>
                    <Link to="/dashboard">
                        <button className="btn-hero-first">Rexístrate</button>
                        <button className="btn-hero-second">Entra no teu panel</button>
                    </Link>
                </div>
                <img className="hero-image" src={hero} alt="Hero" />
            </section>

            <section className="features">
                <h2 className="features-title">Como funciona?</h2>
                <p className="features-subtitle">Primeiro, solicita o teu pal da túa lista de matches. Axiña recibirás unha mensaxe para concertar a túa próxima cita!</p>
                <div className="features-content">
                    <img className="feature-image" src={section} style={{ width: 800 }} alt="Feature" />
                    <div>
                        <div className="feature">
                            <h4>Adaptado ás túas necesidades</h4>
                            <p>Busca entre a nosa selección de pals dispoñibles
                                e escolle o que mellor se adapte á túa forma de ser</p>
                        </div>
                        <div className="feature">
                            <h4>Flexible e inmediato</h4>
                            <p>Aluga ao amigo elixido por un tempo determinado,
                                dende unhas horas ata varios días</p>
                        </div>
                        <div className="feature">
                            <h4>Infinitas posibilidades</h4>
                            <p>Goza da súa compañía no teu evento especial, vacacións, conversacións, actividades e moito máis</p>
                        </div>
                        <div className="feature">
                            <h4>Contamos coa túa opinión!</h4>
                            <p>Valora a túa experiencia e danos a túa opinión sobre o servizo, terémolo en conta para futuras peticións!</p>
                        </div>
                    </div>
                </div>
            </section>


            <section className="pricing">
                <div className="pricing-card">
                    <img className="fa" src={fa1} alt="Hand with heart icon" />
                    <h3 className="price-title">Unha cita</h3>
                    <p className="price">25€<span class="price-ano">/hora</span></p>
                    <p className="price-description">Para persoas que buscan compaña para unha cita concreta</p>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>1 cita</span>
                    </ul>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>1 pal asignado</span>
                    </ul>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>Seguridade e privacidade</span>
                    </ul>
                </div>
                <div className="pricing-card pricing-card-big">
                    <img className="fa" src={fa2} alt="Hand with heart icon" />
                    <h3 className="price-title">Pack mensual</h3>
                    <p className="price">60€<span class="price-ano">/mes</span></p>
                    <p className="price-description">Para persoas que precisan compaña de forma intermitente</p>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>3 citas/mes</span>
                    </ul>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>Ata 3 pals asignades</span>
                    </ul>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>Seguridade e privacidade</span>
                    </ul>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>Escolla libre de actividade</span>
                    </ul>
                </div>
                <div className="pricing-card">
                    <img className="fa" src={fa3} alt="Hand with heart icon" />
                    <h3 className="price-title">Pack Premium</h3>
                    <p className="price">140€<span class="price-ano">/mes</span></p>
                    <p className="price-description">Para persoas que buscan compaña asidua</p>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />

                        <span>Ata 10 citas/mes</span>
                    </ul>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>Ata 5 pals asignades</span>
                    </ul>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>Seguridade e privacidade</span>
                    </ul>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>Escolla libre de actividades</span>
                    </ul>
                    <ul class="price-includes">
                        <CheckOutlined class="price-check" />
                        <span>Posibilidade de citas múltiples (con distintos pals)</span>
                    </ul>
                </div>
            </section>
            <section>
                <div className="opinion-intro">
                    <p className="opinion-subtitle">O que din os nosos usuarios</p>
                    <h2 className="opinion-title">Se precisas unhe amigue,{"\n"}
                        Rent-a-pal axúdache a conseguilo!</h2>
                </div>
                <div className="opinion-block">
                    <Card className="opinion-card">
                        <Avatar className="opinion-avatar" src={<img src={avatar3} alt="avatar" />} />
                        <p>Sempre me custou encontrar amigos que compartan os meus intereses e hobbies, pero grazas a Rent-a-pal, puiden alugar a persoas coas que podo desfrutar do meu tempo libre. Aluguei a alguén para ir a un partido de baloncesto e pasámolo xenial falando de deportes. Agora quedamos regularmente para ir ao ximnasio ou practicar sendeirismo.      </p>
                        <Tag className="opinion-tag" bordered={false} color="orange">
                            Andrea Lance, Deportista empedernida
                        </Tag>
                    </Card>
                    <Card className="opinion-card">
                        <Avatar className="opinion-avatar" src={<img src={avatar1} alt="avatar" />} />
                        <p> A verdade é que me resulta difícil facer amigos na vida real, así que Rent-a-pal foi unha boa solución para min. Aluguei a unha persoa para ir ao cine e resultou ser moi divertida e agradable. Agora alugo regularmente para ir a concertos ou tomar algo nun bar. Sinto menos soidade e máis felicidade dende que a coñecín </p>
                        <Tag className="opinion-tag" bordered={false} color="pink">
                            Ana Martínez, Estudante
                        </Tag>
                    </Card>
                    <Card className="opinion-card">
                        <Avatar className="opinion-avatar" src={<img src={avatar2} alt="avatar" />} />
                        <p>O meu pai faleceu hai pouco e non tiña a ninguén con quen asistir ao funeral. Rent-a-pal axudoume a atopar a alguén que me acompañase neste momento difícil. A persoa que aluguei foi moi comprensiva e ofrecéume o seu apoio emocional. Realmente axudoume a sobrelevar a perda do meu pai.      </p>
                        <Tag className="opinion-tag" bordered={false} color="blue">
                            Juan Sastre, Fillo
                        </Tag>
                    </Card>
                </div>
            </section>
            <footer className="footer">
                <p>&copy;2024 Rent-a-pal. Creado por Aqueladas e Televisión de Galicia. Todos os dereitos reservados.</p>
            </footer>
        </div>
    );
};

export default LandingPage;

