import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { Card, Avatar, List, Button, Tag, Typography, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend } from 'recharts';
import './Dashboard.css';
import moment from 'moment';
const { Title } = Typography;

const tags = ['Viaxes', 'Cultura', 'Literatura'];
const radarData = [
    { name: 'Satisfacción do consumidor', Personal: 180, Equipo: 100 },
    { name: 'Número de citas', Personal: 123, Equipo: 130 },
    { name: 'Tempo de resposta', Personal: 180, Equipo: 140 },
    { name: 'Engagement', Personal: 130, Equipo: 170 },
    { name: 'Ratio de Pals', Personal: 100, Equipo: 90 },
];
const Dashboard = () => {
    const [lastActivities, setLastActivities] = useState([]);

    const fetchLastActivities = async () => {
        const conversationsRef = db.collection('conversations');
        const snapshot = await conversationsRef.get();
    
        const fetchedLastActivitiesPromises = snapshot.docs.map(async doc => {
            const conversationData = doc.data();
            const messagesRef = doc.ref.collection('messages').orderBy('timestamp', 'desc').limit(1);
            const lastMessageSnapshot = await messagesRef.get();
    
            if (!lastMessageSnapshot.empty) {
                const lastMessage = lastMessageSnapshot.docs[0].data();
                const lastMessageTimestamp = lastMessage.timestamp.toDate();

                // Solo incluye el mensaje si el timestamp no está en el futuro
                if (lastMessageTimestamp <= new Date()) {
                    return {
                        id: doc.id,
                        name: conversationData.name,
                        avatar: conversationData.imageUrl,
                        content: lastMessage.content,
                    };
                }
            }
            return null; // Devuelve null para mensajes futuros o si no hay último mensaje
        });
    
        const fetchedLastActivities = await Promise.all(fetchedLastActivitiesPromises);
        const filteredActivities = fetchedLastActivities.filter(activity => activity !== null); // Filtra valores null
        setLastActivities(filteredActivities);
    };

    useEffect(() => {
        fetchLastActivities();
    }, []);

    return (
        <div className="row">
            <div className="last-activity">
                <Card title="Últimos chats abertos">
                    <List
                        dataSource={lastActivities}
                        renderItem={activity => (
                            <List.Item>
                                <Link to={`/chat/${activity.id}`} className="activity-link">
                                <List.Item.Meta
                                    avatar={<Avatar className="avatar" src={activity.avatar} />}
                                    title={<h2 className="activity-name">{activity.name}</h2>}
                                    description={<p className="description">{activity.content}</p>}
                                    />
                                </Link>
                            </List.Item>
                        )}
                    />
                </Card>
            </div>
            <div className="right-column">
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <Card title="As túas preferencias" style={{ width: '100%' }}>
                        <Space wrap>
                            {tags.map((tag, index) => (
                                <Tag key={index} color="#2d3b6d" style={{ width: '100px', fontSize: '16px', textAlign: 'center', padding:'6px'}}>
                                    {tag}
                                </Tag>
                            ))}
                            <Button icon={<PlusOutlined />} disabled>Engadir</Button>
                        </Space>
                    </Card>
                    <Card title="Gráfico de progreso" style={{ width: '100%', marginBottom: '100px', marginRight: '32px'}}>
                        <RadarChart className='RadarChart'
                            cx={300}
                            cy={250}
                            outerRadius={150}
                            width={600}
                            height={500}
                            data={radarData}
                        >
                            <PolarGrid />
                            <PolarAngleAxis dataKey="name" />
                            <PolarRadiusAxis angle={30} domain={[0, 200]} />
                            <Radar
                                name="Andrea"
                                dataKey="Personal"
                                stroke="#2d3b6d"
                                fill="#2d3b6d"
                                fillOpacity={0.3}
                            />
                            <Radar
                                name="Resto do equipo"
                                dataKey="Equipo"
                                stroke="#ffce00"
                                fill="#ffce00"
                                fillOpacity={0.4}
                            />

                            <Legend />
                        </RadarChart>


                    </Card>
                </Space>
            </div>
        </div>
    );
};


export default Dashboard;
